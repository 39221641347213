import {PageRendererProps} from 'gatsby'

import Layout from 'src/components/Layout'
import SEO from 'src/components/SEO'
import ResetPasswordForm from 'src/components/ResetPasswordForm'
import {Flex, Box} from 'src/components/Box'
import {Header1, Lede} from 'src/components/text'
import {Action} from 'src/components/Action'
import {goBack} from 'src/lib/util'
import useViewer from 'src/hooks/useViewer'
import {useToastAction} from 'src/context/ToastContext'
import {simpleToast} from 'src/components/ToastTemplates'
import {WindowLocationWithReturnTo} from 'src/types'

const ResetPassword = ({location}: PageRendererProps) => {
  const {addToast} = useToastAction()
  const {viewer} = useViewer()
  const email =
    viewer === 'not-logged-in'
      ? ''
      : viewer && viewer.student
      ? viewer.student.email
      : ''

  const handleSuccess = () => {
    addToast({
      body: simpleToast('Reset password email sent. Go check!'),
    })
    goBack(location as WindowLocationWithReturnTo)
  }
  return (
    <Layout narrow>
      <SEO title="Reset Password" />
      <Flex flexDirection="column">
        <Box pt={4}>
          <Header1>Reset your password</Header1>
        </Box>

        <Box mt={3}>
          <ResetPasswordForm
            onSuccess={handleSuccess}
            initialValues={{email}}
          />
        </Box>
        <Box mt={3} alignSelf="center">
          <Action
            onClick={() => goBack(location as WindowLocationWithReturnTo)}
          >
            <Lede color="grey4">Cancel</Lede>
          </Action>
        </Box>
      </Flex>
    </Layout>
  )
}

export default ResetPassword
