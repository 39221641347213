import {Form} from 'react-final-form'
import mapValues from 'lodash/mapValues'
import {FieldValidator} from 'final-form'

import Button from 'src/components/Button'
import {resetPassword} from 'src/gs-api'
import {ErrorMessage} from 'src/components/form/input'
import {composeValidators, required, validEmail} from 'src/form/validation'

import TextField from './form/TextField'
import {Flex} from './Box'

interface FormValues {
  email: string
}

interface Props {
  onSuccess: () => void
  initialValues: FormValues
}

const ResetPasswordForm = ({onSuccess, initialValues}: Props) => {
  const onSubmit = ({email}: FormValues) =>
    resetPassword({
      student: {
        email,
      },
    }).then((result) => {
      if ('errors' in result) {
        return mapValues(result.errors, (e) => e.join(' '))
      }
      onSuccess()
    })

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit}>
      {({
        handleSubmit,
        submitError,
        submitting,
        valid,
        dirtySinceLastSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Flex flexDirection="column">
            <TextField
              validate={
                composeValidators(
                  required,
                  validEmail
                ) as FieldValidator<string>
              }
              label="Email"
              name="email"
              type="email"
            />

            {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
            <Button
              busy={submitting}
              disabled={submitting || (!valid && !dirtySinceLastSubmit)}
              type="submit"
            >
              Send me the link
            </Button>
          </Flex>
        </form>
      )}
    </Form>
  )
}

export default ResetPasswordForm
