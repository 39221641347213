import {ToastMessage} from 'src/context/ToastContext'

export const simpleToast = (text: string): ToastMessage['body'] => ({
  text,
})

export const toastWithHeading = ({
  heading,
  emoji,
  text,
}: {
  heading: string
  emoji?: {emoji: string; label: string}
  text: string
}): ToastMessage['body'] => ({
  heading,
  emoji,
  text,
})
